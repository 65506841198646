import * as React from "react";
import {Parallax} from "react-parallax";
import Fade from "@material-ui/core/Fade";
import "../styles/Unlock.scss"
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";

import axios from "axios"
import Reaptcha from "reaptcha";
import queryString from "query-string";

class Unlock extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            captchaToken: null,
            loading: true,
            email: React.createRef(),
            username: React.createRef(),
            captchaPublicKey: null
        }
    }

    componentDidMount() {
        let token = queryString.parse(this.props.location.search).token;
        if (token) {
            axios.post("/api/unlock/submit", {token: token})
                .then(res => res.data)
                .then(json => {
                    if (json.error)
                        this.setState({submiterror: json.message})
                    else
                        this.setState({successmsg: json.message})
                })
        } else {
            // load captcha publickey
            axios.get("/api/config")
                .then(res => res.data)
                .then(json => {
                    this.setState({loading: false, captchaPublicKey: json.captchaPublicKey})
                })
        }
    }

    render() {
        return (
            <Fade in={true} timeout={300}>
                <Parallax
                    bgImage={require('../assets/img/hub_4_4k.png')}
                    bgImageAlt="GrieferGames Spawn"
                    strength={300}
                >
                    <Grid container alignItems={"center"} className={"unlockRoot"}>
                        <Grid item xs={11} sm={7} md={5} lg={4} className={"unlockContainer"}>
                            <div className={"title"}>
                                <div>Freischaltung</div>
                            </div>
                            <div className={"form"}>
                                {
                                    this.state.submiterror != null ?
                                        <div style={{display: "flex", flexDirection: "column"}}>
                                            <b style={{
                                                color: "red",
                                                marginBottom: 30,
                                                fontSize: 30
                                            }}>{this.state.submiterror}</b>
                                        </div> :
                                        this.state.successmsg != null ?
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "center"
                                            }}>
                                                <b style={{
                                                    color: "green",
                                                    marginBottom: 30,
                                                    fontSize: 30
                                                }}>{this.state.successmsg}</b>
                                            </div> :
                                            this.state.loading ?
                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    alignItems: "center"
                                                }}>
                                                    <CircularProgress/><b
                                                    style={{
                                                        color: "white",
                                                        marginBottom: 30
                                                    }}>Bitte warte einige Sekunden...</b></div> :
                                                <>
                                                    <span className={"subtitle"}>Bitte gib deine E-Mail-Adresse und deinen Minecraft-Namen an, um deinen Account zu bestätigen.</span>
                                                    <input placeholder={"E-Mail-Adresse"} ref={this.state.email}/>
                                                    <input placeholder={"Minecraft-Name"} ref={this.state.username}/>
                                                    <Reaptcha sitekey={this.state.captchaPublicKey}
                                                              onVerify={res => this.setState({
                                                                  captchaToken: res,
                                                                  errormsg: null
                                                              })}
                                                              theme={"dark"}
                                                    />
                                                    <span style={{
                                                        color: "red",
                                                        fontWeight: "bold",
                                                        fontSize: 20,
                                                        textShadow: "0px 0px 3px black"
                                                    }}>{this.state.errormsg}</span>
                                                    <input type={"button"} value={"Verifizieren"}
                                                           onClick={() => {
                                                               let email = this.state.email.current.value;
                                                               let username = this.state.username.current.value;
                                                               let sitekey = this.state.captchaToken;
                                                               /*if (sitekey == null) {
                                                                   this.setState({errormsg: "Bitte bestätige, dass du kein Bot bist."})
                                                                   return;
                                                               }*/
                                                               this.setState({loading: true});
                                                               axios.post("/api/unlock", {
                                                                   email: email,
                                                                   username: username,
                                                                   token: sitekey
                                                               }).then(res => res.data)
                                                                   .then(json => {
                                                                       this.setState({loading: false})
                                                                       if (json.error) {
                                                                           this.setState({errormsg: json.message})
                                                                       } else {
                                                                           this.setState({successmsg: json.message})
                                                                       }
                                                                   })
                                                           }}/>
                                                    <span>Mit dem Absenden des Formulars akzeptierst du die Datenschutzbedingungen. Die Daten werden ausschließlich zum Versand der E-Mail gebraucht.</span>
                                                </>
                                }
                            </div>
                        </Grid>
                    </Grid>
                </Parallax>
            </Fade>
        )
    }
}

export default Unlock
